import React from 'react';

import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        paddingTop: ({ mobilePaddingTop }) => mobilePaddingTop,
        paddingBottom: ({ mobilePaddingBottom }) => mobilePaddingBottom,
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: ({ paddingTop }) => paddingTop,
        paddingBottom: ({ paddingBottom }) => paddingBottom,
      },
    },
  })
);

const FanGoBody = ({
  children,
  backgroundColor = null,
  paddingTop = '50px',
  paddingBottom = '50px',
  mobilePaddingBottom = '24px',
  mobilePaddingTop = '24px',
  alternate = false,
  display = null,
  rowGap = null,
  flexDirection = null,
}) => {
  const classes = useStyles({
    paddingTop,
    paddingBottom,
    mobilePaddingBottom,
    mobilePaddingTop,
  });
  return (
    <div
      className={classes.container}
      style={{
        width: '100%',
        backgroundColor: backgroundColor
          ? backgroundColor
          : alternate
          ? '#fafafa'
          : '#ffffff',
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div
        style={{
          maxWidth: 1200,
          width: '100%',
          margin: 'auto',
          display: display,
          rowGap: rowGap,
          flexDirection: flexDirection,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default FanGoBody;
